* {
  margin: 0;
  padding: 0;
}
body {
  background: #fff5d7;
  color: #2b2834;

}

nav {
  height: 70px;
  position: relative;
  /* background: rgb(196, 144, 245); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 80px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
nav .digiMenu {
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 2.2rem;
  height: 2rem;
}
nav .digiMenu span {
  height: 0.4rem;
  width: 100%;
  background-color: #ec986e;
  border-radius: 0.2rem;
}
.digitest {
  font-size: 40px;
}
.class-logo {
  color: #ec986e;
}
.lessons {
  padding-right: 20px;

}
.links {
  /* color: rgb(99, 99, 40); */
  color: #2b2834;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: bolder;
  text-decoration: none;
}
.link-login {
  margin-right: 10px;
  border: 2px solid #ec986e;
  
}
.link-register {
  background: #ffcab0;
  border-radius: 25px;
  transition: 0.8s;

}
.link-register:hover {
  background-color: #2b2834;
  color: white;
}
.Geo-main {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center;

}

.Geography {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: auto;

}
.Geo-class h1 {
  font-size: 70px;
}
.Geo-class p {
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1px;
}
.Geography img {
  width: 300px;
  height: 300px;
}

@media(max-width: 650px) {
  nav {
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-left: 5px;
    padding-right: 8px;
  }
  nav .digiMenu {
    display: flex;
  }
  nav .logReg {
    display: none;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
  }
  .openM {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
  }
  nav .openM .links {
    width: 200px;
    margin-bottom: 15px;
  }
  .openM .link-login {
    padding: 13px 18px;
    border-radius: 25px;
  }
  .Geography {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .Geo-class h1 {
    font-size: 30px;
  }
  .Geo-class p {
    line-height: 1.2rem;
    letter-spacing: 0.1rem;
  }

  
}

/* @@@@@@@@@ LOGIN PAGE @@@@@@@@@ */


.login-div {
  height: 100%;
  width: 300px;
  background: #f1e5c1;
  margin: auto;
  margin-top: 50px;
  padding: 20px;
  text-align: center;
  
}
.login-div h2 {
  text-align: center;
  margin-bottom: 20px;
}
.align-left {
  text-align: left;
}

.all-inputs {
  box-sizing: border-box;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.inp-divs {
  margin-bottom: 15px;
}
.login-btn {
  box-sizing: border-box;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: #ffcab0;
  font-weight: bolder;
  transition: 0.8s;
}
.login-btn:hover {
  background-color: #2b2834;
  color: white;
  cursor: pointer;
}

.or {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

}
.create-account {
  text-align: center;
  /* color: #2b2834; */
  color: #ec986e;
  /* color: white; */
  font-weight: bolder;
  /* text-decoration: none; */
}

/* @@@@@@@@@@@@@ SIGNUP SECTION @@@@@@@@@@@@ */

.signup-div {
  height: 100%;
  width: 400px;
  background: #f1e5c1;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  text-align: center;
}

.signup-btn {
  box-sizing: border-box;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: #ffcab0;
  font-weight: bolder;
  transition: 0.8s;

}
.signup-btn:hover {
  background-color: #2b2834;
  color: white;
  cursor: pointer;
}
.log-account {
  text-align: center;
  /* color: #2b2834; */
  color: #ec986e;
  /* color: white; */
  font-weight: bolder;
}
@media(max-width: 650px) {
  .signup-div {
    width: 80%;
  }
}

/* @@@@@@@@@@ HOMEPAGE @@@@@@@@@ */

.homepage {
  width: 80%;
  margin: auto;
  text-align: center;
  /* background: yellowgreen; */
  padding-bottom: 50px;
  padding-top: 30px;

}
.std-name {
  color: #ec986e;
  font-weight: bolder;
  font-family: cursive;
}
.lesson-1 {
  padding-top: 20px;
}
.pdf {
  margin: auto;
  width: 80%;
  height: 200px;
  display: grid;
  grid-template: auto auto /repeat(2, 1fr);
  /* grid-template-columns: 250px 250px; */
  /* background: blue; */
  /* padding-left: 200px; */
  
}

.ind-pdf {
  height: 50px;
  width:300px;
  /* border: 2px solid green; */
  margin-bottom: 10px;
  margin: auto;
  padding-top: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.ind-pdf:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.ind-pdf a {
  color: #ec986e;
  font-weight: bolder;
}
.videos {
  width: 80%;
  /* height: 120px; */
  display: grid;
  grid-template: auto auto /repeat(2, 1fr);
  /* grid-template-columns: 250px 250px; */
  /* background: blue; */
  /* padding-left: 100px; */
  margin: auto;
  margin-bottom: 20px;

}
.vid-divs {
  margin: auto;
}
video {
  margin-top: -10px;
  margin-bottom: 15px;
}
.examLink {
  background: #ffcab0;
  border-radius: 25px;
  transition: 0.8s;
  padding: 15px 20px;
  color: #2b2834;
  font-weight: bolder;
  text-decoration: none;
  font-size: 30px;

}
.examLink:hover {
  background-color: #2b2834;
  color: white;
  cursor: pointer;
}
@media(max-width: 650px) {
  .pdf {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* margin-left: 10px; */
    
  }
  .ind-pdf {
    margin-bottom: 10px;
  }
  .videos {
    display: flex;
    flex-direction: column;
  }
}

/* @@@@@@@@@ Exam Section @@@@@@@@@ */

.exam-div {
  width: 80%;
  margin: auto;
}
.quest-div {
  margin-bottom: 10px;
  text-align: left;
}
.questList {
  list-style: none;
}
.theQuestion {
  font-size: 30px;
}

/* @@@@@@@@ EXAM @@@@@@@@@@@ */

.displace-form {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 30px;
}
.displace-form h1 {
  text-align: center;
  color: #ec986e;
  /* margin-bottom: 20px; */

}
.displace-form hr {
  margin-bottom: 20px;

}
.quiz-btn {
  background: #ffcab0;
  border-radius: 25px;
  padding: 15px 20px;
  border: none;
  font-weight: bolder;
  font-size: 25px;
  transition: 0.8s;
}
.quiz-btn:hover {
  background-color: #2b2834;
  color: white;
  cursor: pointer;
}
.showRtsBtn {
  text-align: center;
  margin-bottom: 30px;
}
.showResults {
  background-color: #2b2834;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  padding: 15px 20px;
  border: none;
  font-weight: bolder;
  font-size: 25px;
  transition: 0.8s;
}
.showResults:hover {
  background: #ffcab0;
}

.rst-div {
  height: 200px;
  width: 400px;
  text-align: center;
  padding-top: 40px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.mailBtn {
  background: #ffcab0;
  border: none;
  margin-top: 20px;
  font-weight: bolder;
  font-size: 25px;
  border-radius: 25px;
  padding: 15px 20px;
  cursor: pointer;
  transition: 0.8s;
}
.mailBtn:hover {
  background-color: #2b2834;
  color: white;

}
.lesson2Btn {
  font-weight: bolder;
  font-size: 25px;
  border-radius: 25px;
  padding: 15px 20px;
  background-color: #2b2834;
  color: white;
  text-decoration: none;
}
.lesson2Btn:hover {
  background-color: #605e65;
}
.resultDiv {
  text-align: center;
  margin: auto;
}
.replyStatement {
  color: green;
  font-weight: bolder;
}
@media(max-width: 650px) {
  .rst-div {
    width: 80%;
  }
  .mailBtn {
    font-size: 20px;
  }
}
/* @@@@@@@@@@@ LESSON 2 @@@@@@@@@@ */

.lesson2Div {
  text-align: center;
  margin-top: 40px;
}

.comingsoon {
  margin-bottom: 30px;
}